import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import "../login/login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User logged in Successfully");
      history.replace(from);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <p>to get started</p>

        <div className="input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <p className="forgot-password">Forgot Password?</p>

        <button type="submit" className="submit-button">
          Continue
        </button>

        <p className="register-text">
          New user? <a href="/register">Register</a>
        </p>
      </form>
    </div>
  );
}

export default Login;
